"use client";

import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { isEmpty } from "lodash";
import { useEffect } from "react";

const amplitudeApiKey = process.env.AMPLITUDE_API_KEY;

export function Amplitude() {
  console.log("Amplitude component", { amplitudeApiKey });
  useEffect(() => {
    if (amplitudeApiKey && !isEmpty(amplitudeApiKey)) {
      console.log("Initializing amplitude with apiKey", amplitudeApiKey);
      const sessionReplayTracking = sessionReplayPlugin();
      amplitude.add(sessionReplayTracking);
      amplitude.init(amplitudeApiKey, {
        autocapture: true,
        defaultTracking: {
          sessions: true,
        },
      });
    } else {
      console.log("Skipping amplitude setup. AMPLITUDE_API_KEY not set");
    }
  }, []);

  return <></>;
}
